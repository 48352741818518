import React from 'react'

import SVG from '@/assets/svg/logo.svg'

import { useLanguageContext } from '@/base/context/LanguageContext'
import { getCaminoFinancialHomePage } from '@/base/language/getPagePath'

import { Link } from '@/components/elements/Link'

import * as styles from './styles.module.css'

const renderProperLinkType = (link) => {
  const match = link.match(/(mailto:|tel:|fax:|http:\/\/|https:\/\/)(.*)/)

  if (match) {
    let prefix = ''
    if (match[1] === 'tel:') {
      prefix = 'Tel '
    }

    if (match[1] === 'fax:') {
      prefix = 'Fax '
    }

    return (
      <span>
        {prefix}
        <Link link={{ url: link, withUtmParameters: match[1].includes('http') }}>{match[2]}</Link>
      </span>
    )
  }

  return <span>{link}</span>
}

export const SubfooterLinks = ({ links }) => {
  const { language_code } = useLanguageContext()

  const homePath = getCaminoFinancialHomePage({ language_code })

  return (
    <div className={styles.subfooter}>
      <div>
        <Link link={{ url: homePath, withUtmParameters: true }}>
          <SVG height="52" />
        </Link>
      </div>

      <div className={styles.links}>{links?.map(renderProperLinkType)}</div>
    </div>
  )
}
