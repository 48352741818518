import React from 'react'

import * as styles from './styles.module.css'

export const Input = ({ title, value, setValue, errorMessage, component: Component, inputProps }) => {
  const onChange = (e) => setValue(e.target.value)

  return (
    <div>
      <Component {...{ value, onChange, placeholder: title, ...inputProps }} />
      <div className={styles.errorMessage} visible={String(!!errorMessage)}>
        {errorMessage}
      </div>
    </div>
  )
}
