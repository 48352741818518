import React from 'react'

import { List } from '@/components/elements/List'
import { FooterColumn } from '@/components/modules/FooterColumn'
import { Section } from '@/components/modules/Section'
import { SubfooterDisclaimer } from '@/components/modules/SubfooterDisclaimer'
import { SubfooterLinks } from '@/components/modules/SubfooterLinks'

import * as styles from './styles.module.css'

export const Footer = ({ columns, subfooterLinks, subfooterDisclaimer, legalLinks }) => (
  <footer className={styles.footer}>
    <Section with-padding="true">
      <List items={columns} component={FooterColumn} className={styles.columns} />
      <hr />
      <SubfooterLinks links={subfooterLinks} />
      <hr />
      <SubfooterDisclaimer disclaimer={subfooterDisclaimer} links={legalLinks} />
    </Section>
  </footer>
)
