import React from 'react'

import { useLanguageContext } from '@/base/context/LanguageContext'
import { sleep } from '@/base/helpers/sleep'

import { Button } from '@/components/elements/Button'
import { InputText } from '@/components/elements/Inputs/Text'
import { useValidatedInput } from '@/components/modules/LeadForm/useValidatedInput'

import * as styles from './styles.module.css'

const getMessageColor = (statusCode) => (statusCode === 200 ? '--color--green-1' : '--color--red-1')

export const Newsletter = () => {
  const { language_code, t } = useLanguageContext()

  const [responseCode, setResponseCode] = React.useState()
  const [isLoading, setIsLoading] = React.useState()
  const [message, setMessage] = React.useState('')

  const [InputEmail, onEmailSubmit] = useValidatedInput({
    component: InputText,
    title: t`form.email`,
    inputProps: { type: 'email' },
    required: true,
    regex: /.+@[^@]+\.[^@]{2,}$/,
  })

  React.useEffect(() => {
    responseCode && setMessage(t([`newsletter.status.${responseCode}`]))
  }, [responseCode])

  const onSubmit = async (e) => {
    e.preventDefault()

    setResponseCode()
    setMessage('')

    const [valueEmail, isEmailValid] = onEmailSubmit()

    if (isEmailValid) {
      setIsLoading(true)

      const response = await fetch('/.netlify/functions/newsletter-sign-up', {
        method: 'POST',
        body: JSON.stringify({ email_address: valueEmail, language_code }),
      })

      await sleep(1000) // prevents "jumpy" behaviour of the loading indicator when the request is quick

      setIsLoading(false)
      setResponseCode(response.status)
    }
  }

  return (
    <form noValidate>
      <div className={styles.input}>
        {InputEmail}
        <Button type="submit" theme="secondary" is-loading={String(isLoading)} onClick={onSubmit}>
          {t`newsletter.button_sign_up`}
        </Button>
      </div>

      <div
        className={styles.message}
        visible={String(!!message)}
        style={{ color: `var(${getMessageColor(responseCode)})` }}
      >
        {message}
      </div>
    </form>
  )
}
