import React from 'react'

import { useLanguageContext } from '@/base/context/LanguageContext'

import { Input } from './Input'

export const useValidatedInput = ({ required, regex, ...rest }) => {
  const { t } = useLanguageContext()

  const [value, setValue] = React.useState()
  const [errorMessage, setErrorMessage] = React.useState()

  const validateRequired = ({ required, value }) => {
    let isValid = true

    if (required) {
      isValid = value !== ''

      if (isValid) {
        setErrorMessage()
      } else {
        setErrorMessage(`${rest.title} ${t`form.is_required`}`)
      }
    }

    return isValid
  }

  const validateRegex = ({ regex, value }) => {
    let isValid = true

    if (regex && value) {
      isValid = regex.test(value)

      if (isValid) {
        setErrorMessage()
      } else {
        setErrorMessage(`${rest.title} ${t`form.is_not_valid`}`)
      }
    }

    return isValid
  }

  React.useEffect(() => {
    validateRequired({ required, value })
  }, [required, value])

  const onSubmit = () => {
    let input_value = value || ''

    setValue(input_value)

    const isRequiredValid = validateRequired({ required, value: input_value })
    const isRegexValid = validateRegex({ regex, value: input_value })

    return [value, isRequiredValid && isRegexValid]
  }

  const input = <Input {...{ value, setValue, errorMessage, ...rest }} />

  return [input, onSubmit]
}
