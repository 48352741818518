import React from 'react'
import Helmet from 'react-helmet'
import ReactHtmlParser from 'react-html-parser'

export const SEO = ({ title, description, image, location, yoastHead }) => (
  <Helmet>
    {title && <title>{title}</title>}
    {description && <meta name="description" content={description} />}
    {image && <meta name="image" content={image.file?.url} />}

    {location && <meta property="og:url" content={location.href} />}
    {location && <meta property="og:type" content="website" />}
    {title && <meta property="og:title" content={title} />}
    {description && <meta property="og:description" content={description} />}
    {image && <meta property="og:image" content={image.file?.url} />}

    {yoastHead && ReactHtmlParser(yoastHead)}
  </Helmet>
)
