import React from 'react'

import { Spinner } from '@/components/elements/Spinner'

import * as styles from './styles.module.css'

export const Button = ({ children, ...rest }) => (
  <button {...{ className: styles.button, ...rest }}>
    {rest['is-loading'] === 'true' && <Spinner />}
    {children}
  </button>
)
