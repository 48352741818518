import React from 'react'

import { Image } from '@/components/elements/Image'

import * as styles from './styles.module.css'

export const SocialLink = ({ icon, link: { url } }) => (
  <a href={url} target="_blank" className={styles.socialLink}>
    <Image image={icon} />
  </a>
)
