import React from 'react'

import accreditedBusinessPNG from '@/assets/icons/accredited-business.png'

import { useLanguageContext } from '@/base/context/LanguageContext'

import { Link } from '@/components/elements/Link'
import { List } from '@/components/elements/List'
import { LinkLanguageToggle } from '@/components/modules/LinkLanguageToggle'

import * as styles from './styles.module.css'

export const SubfooterDisclaimer = ({ disclaimer, links }) => {
  const { t } = useLanguageContext()

  return (
    <div className={styles.subfooter}>
      <div>
        <List items={links} component={Link} className={styles.links} />
        <div className={styles.languageToggle}>
          <LinkLanguageToggle variant="link-uppercase" theme="dark" />
        </div>
      </div>
      <div className={styles.disclaimer}>
        {disclaimer}
        <div className={styles.logos}>
          <a href={t`accredited_business_link`} target="_blank">
            <img src={accreditedBusinessPNG} alt="accredited-business" />
          </a>
        </div>
      </div>
    </div>
  )
}
