import React from 'react'

import { getContentfulElementParagraph } from '@/base/contentful/getContentfulElementParagraph'

import { Link } from '@/components/elements/Link'
import { List } from '@/components/elements/List'
import { SocialLink } from '@/components/elements/SocialLink'
import { Newsletter } from '@/components/modules/Newsletter'

import * as styles from './styles.module.css'

export const FooterColumn = ({ title, description, newsletter, socialLinks, links, children }) => (
  <div className={styles.column}>
    <h4>{title}</h4>
    {getContentfulElementParagraph(description)}
    {newsletter && <Newsletter />}
    <List items={socialLinks} component={SocialLink} className={styles.socialLinks} />
    <List items={links} component={Link} itemProps={{ variant: 'link-arrow' }} className={styles.links} />
    {children}
  </div>
)
